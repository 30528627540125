import React, { FC, useEffect } from 'react';
import { useIntl } from 'context/IntlContext';
import { useColorMode } from 'theme-ui';

import {
  Grid,
  Flex,
  Heading,
} from 'components';

import Section from '../../components/Section';
import Motto from '../../components/Motto';
import Electronic from '../../images/icons/electronic.svg';
import Automated from '../../images/icons/automated.svg';
import Protection from '../../images/icons/protection.svg';
import Notifications from '../../images/icons/notifications.svg';
import Seo from '../../components/SEO';

const PAGE_COLOR_MODE = 'light';

const Security: FC = () => {
  const { t } = useIntl();

  const [colorMode, setColorMode] = useColorMode();
  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
  }, [colorMode, setColorMode]);

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Section>
        <Heading
          as="h1"
          sx={{
            variant: 'text.heroTitle',
            color: 'quaternary',
            mb: 7,
          }}
        >
          {t('homepage.company.security.title')}
        </Heading>
        <Grid gap={[0, 0, 14]} columns={[1, '1fr', '1fr 1fr']}>
          <Motto
            icon={<Electronic />}
            title={t('homepage.company.security.emi.title')}
            description={t('homepage.company.security.emi.subtitle')}
          />
          <Motto
            icon={<Automated />}
            title={t('homepage.company.security.compliance.title')}
            description={t('homepage.company.security.compliance.subtitle')}
          />
          <Motto
            icon={<Protection />}
            title={t('homepage.company.security.multi_level.title')}
            description={t('homepage.company.security.multi_level.subtitle')}
          />
          <Motto
            icon={<Notifications />}
            title={t('homepage.company.security.notifications.title')}
            description={t('homepage.company.security.notifications.subtitle')}
          />
        </Grid>
      </Section>
      <Section noDivider>
        <Heading
          as="h1"
          sx={{
            variant: 'text.sectionTitle',
            color: 'quaternary',
            mb: 7,
          }}
        >
          {t('homepage.company.security.tips_section.title')}
        </Heading>
        {/* <Text color="quaternary">
          {t('homepage.company.affiliate.benefits.subtitle') ||
            'We should have a resume of what’s so innovative about in this line.'}
        </Text> */}
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          <Motto
            title={t('homepage.company.security.tips_section.phising.title')}
            description={t(
              'homepage.company.security.tips_section.phising.subtitle',
            )}
          />
          <Motto
            title={t('homepage.company.security.tips_section.pharming.title')}
            description={t(
              'homepage.company.security.tips_section.pharming.subtitle',
            )}
          />
          <Motto
            title={t(
              'homepage.company.security.tips_section.public_access.title',
            )}
            description={t(
              'homepage.company.security.tips_section.public_access.subtitle',
            )}
          />
        </Flex>
      </Section>
    </>
  );
};

export default Security;
